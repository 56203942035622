<template>
<div class="card">
    <div class="card-header border-0 pt-6 bg-white row m-0">
        <div class="d-flex p-0">
            <div class="card-title">
                <span>Distribusi Raw Product</span>
            </div>
            <div class="card-toolbar" style="margin-left:auto">
                <div class="d-flex justify-content-end" data-kt-table-toolbar="base">
                    <router-link :to="'/distribusi/history'" class="btn btn-primary">Riwayat</router-link>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body pt-0 tab-content text-gray-700" id="tab_raw_product">
        <el-form
            ref="formRef"
            :model="tempData"
            class="form w-100 my-5"
        >
            <div class="col row">
                <div class="col me-10">
                    <el-form-item
                      class="fv-row mb-7"
                      prop="store_id"
                      :rules="[
                        { required: true, message: 'Store is required' },
                      ]"
                    >
                        <label class="required fs-6 fw-bold">Tujuan</label>
                      <el-select name="brand_id" v-model="tempData.store_id" class="form-control p-0 border-0" placeholder="Pilih toko tujuan" size="large" @change="switchStore()">
                        <el-option
                          v-for="(item, i) in listStore"
                          :key="i"
                          :label="item.name"
                          :value="item.id"
                        >
                          <div class="d-flex">
                            <div class="col me-3">{{ item.name }}</div>
                          </div>
                        </el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item
                      class="fv-row mb-7"
                      prop="note"
                      :rules="[
                        { required: true, message: 'Note is required' },
                      ]"
                    >
                        <label class="required fs-6 fw-bold">Keterangan</label>
                      <el-input name="note" v-model="tempData.note" class="form-control p-0 border-0" placeholder="Masukkan keterangan" size="large" />
                    </el-form-item>
                </div>
                
                <div class="col me-10">
                    <el-form-item
                      class="fv-row mb-7"
                      prop="pengirim_id"
                      :rules="[
                        { required: true, message: 'Pengirim is required' },
                      ]"
                    >
                        <label class="required fs-6 fw-bold">Nama Pengirim</label>
                        <el-autocomplete
                          v-model="queryPengirim"
                          :fetch-suggestions="filterPengirim"
                          placeholder="Masukkan nama pengirim"
                          class="form-control p-0 border-0"
                          @select="handlePengirim"
                          name="pengirim"
                        >
                          <template #default="{ item }">
                            <div class="value">{{ item.nama_user }}</div>
                          </template>
                        </el-autocomplete>
                    </el-form-item>

                    <el-form-item
                      class="fv-row mb-7"
                      prop="penerima_id"
                      :rules="[
                        { required: true, message: 'Penerima is required' },
                      ]"
                    >
                        <label class="required fs-6 fw-bold">Nama Penerima</label>
                        <el-autocomplete
                          v-model="queryPenerima"
                          :fetch-suggestions="filterPenerima"
                          placeholder="Masukkan nama pengirim"
                          class="form-control p-0 border-0"
                          @select="handlePenerima"
                        >
                          <template #default="{ item }">
                            <div class="value">{{ item.nama_user }}</div>
                          </template>
                        </el-autocomplete>
                    </el-form-item>
                </div>

                <div class="col">
                    <el-form-item
                      class="fv-row mb-7"
                      prop="type"
                      :rules="[
                        { required: true, message: 'Type is required' },
                      ]"
                    >
                        <label class="required fs-6 fw-bold">Pilih Raw</label>
                        <div class="d-flex form-control border-0 px-0">
                            <div class="form-check form-check-custom me-5">
                                <input class="form-check-input" type="radio" name="type" :value="'item'" id="item" v-model="tempData.type" checked="checked" @change="switchRaw()" />
                                <label for="item" class="form-check-label fs-6 fw-bold">Item</label>
                            </div>
                            <div class="form-check form-check-custom me-5">
                                <input class="form-check-input" type="radio" name="type" :value="'desain'" id="desain" v-model="tempData.type" @change="switchRaw()" />
                                <label for="desain" class="form-check-label fs-6 fw-bold">Desain</label>
                            </div>
                        </div>
                        <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                                 <ErrorMessage name="type" />
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item
                      class="fv-row mb-7"
                    >
                        <label class="fs-6 required fw-bold">Tambah Produk</label>
                        <div class="d-flex form-control border-0 px-0">
                            <div class="form-check form-check-custom me-5">
                                <input class="form-check-input" type="radio" name="add_product" :value="'import'" id="item" checked="checked" @click="modalImport = true" />
                                <label for="item" class="form-check-label fs-6 fw-bold">Import</label>
                            </div>
                            <div class="form-check form-check-custom me-5">
                                <input class="form-check-input" type="radio" name="add_product" :value="'search'" id="search" @click="getRaw()"/>
                                <label for="search" class="form-check-label fs-6 fw-bold">Cari</label>
                            </div>
                        </div>
                        <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                                 <ErrorMessage name="type" />
                            </div>
                        </div>
                    </el-form-item>
                </div>
            </div>
            
            <div class="fv-row mb-7 mt-7" v-if="prevItem.length > 0">
                <div class="fs-6 fw-bolder mb-2 text-uppercase">
                    List Raw Item 
                    <span class="ms-2 fs-7" v-if="prevItem.length > 0">
                        <span v-if="prevItem.every(o => o.status == 1)" class="badge badge-success">
                            Ready
                        </span>
                        <span v-else class="badge badge-warning">
                            Pending
                        </span>
                    </span>
                </div>
                <el-table 
                    :data="prevItem"
                    style="width: 100%; max-height: 800px;"
                    header-row-class-name="text-uppercase"
                >
                    <el-table-column prop="index" label="No" width="50" />
                    <el-table-column prop="raw_category" label="Kategori" width="100" />
                    <el-table-column prop="artikel" label="Artikel" width="100" />
                    <el-table-column prop="sku" label="SKU" width="150" />
                    <el-table-column prop="color" label="Warna" width="120" />
                    <el-table-column prop="size" label="Ukuran" width="80" />
                    <el-table-column prop="stok_warehouse" label="Warehouse" width="150" align="right"/>
                    <el-table-column prop="stok_store" :label="tempData.store_name.replace('NIMCO', '')" width="150" align="right"/>
                    <el-table-column prop="qty" label="Jumlah" width="125" sortable align="right"/>
                    <el-table-column prop="nama_vendor" label="Vendor" width="150" />
                    <el-table-column prop="status" label="Status" width="110" sortable>
                        <template #default="scope">
                            <span class="w-100 d-inline-block text-center" v-if="scope.row.status == 1"><i class="fas fa-check text-success"></i></span>
                            <span class="w-100 d-inline-block text-center" v-else><i class="fas fa-times text-danger"></i></span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="action" label="Action" width="150" align="right">
                        <template #default="scope">
                            <div class="d-flex justify-content-end">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-light mx-2"
                                  @click="editRaw(scope.row)"
                                >
                                  <span class="indicator-label"> <i class="fas fa-pencil-alt"></i> </span>
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-sm btn-danger mx-2"
                                  @click="removeRaw(scope.row.index-1)"
                                >
                                  <span class="indicator-label"> <i class="fas fa-trash-alt"></i> </span>
                                </button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div v-if="prevItem.some(o => o.status == 0)" class="fs-6 fw-bolder mb-2 mt-5">
                    <span>Note: Beberapa produk invalid (tidak ditemukan) atau melebihi batas stok WAREHOUSE, silakan cek kembali!</span>
                </div>
            </div>

            <div class="fv-row mb-7 mt-7" v-if="prevDesain.length > 0">
                <div class="fs-6 fw-bolder mb-2 text-uppercase">
                    List Raw Desain 
                    <span class="ms-2 fs-7" v-if="prevDesain.length > 0">
                        <span v-if="prevDesain.every(o => o.status == 1)" class="badge badge-success">
                            Ready
                        </span>
                        <span v-else class="badge badge-warning">
                            Pending
                        </span>
                    </span>
                </div>
                <el-table
                    :data="prevDesain"
                    style="width: 100%; max-height: 800px;"
                    header-row-class-name="text-uppercase"
                >
                    <el-table-column prop="index" label="No" width="50" />
                    <el-table-column prop="gambar" label="Gambar">
                        <template #default="scope">
                            <img :src="img_url_desain+scope.row.gambar" style="width: 100px; height: 100px;" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="sku" label="Desain ID" width="150" />
                    <el-table-column prop="tipe_desain" label="Tipe" width="150" sortable />
                    <el-table-column prop="jenis" label="Jenis" width="150" sortable />
                    <el-table-column prop="stok_warehouse" label="Warehouse" width="150" sortable align="right"/>
                    <el-table-column prop="stok_store" :label="tempData.store_name.replace('NIMCO', '')" width="150" sortable align="right"/>
                    <el-table-column prop="qty" label="Jumlah" width="125" sortable align="right"/>
                    <el-table-column prop="status" label="Status" width="110" sortable>
                        <template #default="scope">
                            <span class="w-100 d-inline-block text-center" v-if="scope.row.status == 1"><i class="fas fa-check text-success"></i></span>
                            <span class="w-100 d-inline-block text-center" v-else><i class="fas fa-times text-danger"></i></span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="action" label="Action" width="110">
                        <template #default="scope">
                            <button
                              type="button"
                              class="btn btn-sm btn-danger justify-content-end"
                              @click="removeRaw(scope.row.index-1)"
                            >
                              <span class="indicator-label"> <i class="fas fa-trash-alt"></i> </span>
                          </button>
                        </template>
                    </el-table-column>
                </el-table>

                <div v-if="prevDesain.some(o => o.status == 0)" class="fs-6 mb-2 mt-5">
                    <span>Note: Beberapa produk melebihi batas stok WAREHOUSE, silakan cek kembali!</span>
                </div>
            </div>

            <div class="row justify-content-center">
                  <button
                      type="button"
                        @click="onSubmit(formRef)"
                      ref="submitButton"
                      class="btn btn-primary w-350px mt-7 justify-content-end"
                      :data-kt-indicator="loadingSubmit ? 'on' : 'off'"
                  >
                      <span class="indicator-label"> Distribusi Sekarang </span>

                      <span class="indicator-progress">
                          Please wait...
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                  </button>
            </div>
        </el-form>

    </div>

    <el-dialog v-model="modalImport" :title="'IMPORT '+tempData.type.toUpperCase()" :close-on-click-modal="true" width="400px">
        <div> 
            <div class="mb-2">
                <i><a href="https://drive.google.com/file/d/1QQJAXyYL3IESUB_HthTIBg-R5kJyZOVw/view?usp=sharing" target="blank">Lihat Contoh Format</a></i>
            </div>
            <input type="file" class="form-control" name="files" @change="onFileChange($event)" id="files" accept="text/plain"/>
            <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                     <ErrorMessage name="files" />
                </div>
            </div>
        </div>
    </el-dialog>

    <el-dialog v-model="modalItem" :title="'List Item'" :close-on-click-modal="true" width="800px">
        <div class="filter position-relative mb-4 fs-6 fw-bolder text-gray-600" ref="searchRef">
            <div class="filter-item">
                <span>KATEGORI</span>
                <el-select v-model="filter.category_id" placeholder="Pilih Kategori" clearable @change="onFilter()">
                    <el-option v-for="(o, i) in listCategory" :key="i" :value="o.id" :label="o.name"/>
                </el-select>
            </div>
            <div class="filter-item">
                <span>Tipe</span>
                <el-select v-model="filter.tipe_produk" @change="onFilter()" @clear="onFilter()" placeholder="Tipe Produk" clearable style="width: 100px;">
                    <el-option v-for="(o, i) in ['MTO', 'RTW']" :key="i" :value="o" :label="o" />
                </el-select>
            </div>     
            <div class="filter-item">
                <span>Search</span>
                <el-input v-model="filter.search" placeholder="Search" clearable />
            </div>
            <div class="d-flex">
                <button class="btn btn-icon btn-light-primary me-3" @click="onFilter()"><i class="fas fa-search"></i></button>
                <button class="btn btn-icon btn-light-danger" @click="resetFilter()"><i class="fas fa-times"></i></button>
            </div>
        </div>
        <div>
            <table class="table table-rounded table-row-dashed table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                <thead>
                    <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                        <th>#</th>
                        <th>Gambar</th>
                        <th>Tipe Produk</th>
                        <th>Kategori</th>
                        <th>Warna</th>
                        <th>Ukuran</th>
                        <th>SKU</th>
                        <th>Artikel</th>
                        <th>Vendor</th>
                        <th class="text-end w-75px">Stok</th>
                        <th class="text-end w-75px">Action</th>
                    </tr>
                </thead>
                <tbody class="fw-bold text-gray-600">
                    <tr v-for="(item, i) in listProduct" :key="i" class="cursor-pointer" title="Pilih produk">
                        <td scope="row">{{ item.index }}</td>
                        <td>
                            <img class="tbl-image" @click="viewImage(item)" :src="item.tipe_produk == 'MTO' ? img_url_product+item.gambar : img_url_product+item.gambar">
                        </td>
                        <td>{{ item.tipe_produk }}</td>
                        <td>{{ item.raw_category }}</td>
                        <td>{{ item.color }}</td>
                        <td>{{ item.size }}</td>
                        <td>{{ item.sku }}</td>
                        <td>{{ item.artikel }}</td>
                        <td>{{ item.nama_vendor }}</td>
                        <td class="text-end w-75px">{{ item.stok_warehouse }}</td>
                        <td class="text-end w-75px">
                            <button class="btn btn-icon btn-light-primary" @click="modalQty = true, tempData.raw = item"><i class="fas fa-plus"></i></button>
                        </td>
                    </tr>
                </tbody>
                <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </table>
        </div>
        <template #footer>
            <div class="text-center">
                <el-pagination background layout="prev, pager, next" :page-count="pagination.total_page" :pager-count="4" @next-click="handlePageChange" @prev-click="handlePageChange" @current-change="handlePageChange" v-model:current-page="pagination.page" />
            </div>
        </template>
    </el-dialog>

    <el-dialog v-model="modalDesain" :title="'List Desain'" :close-on-click-modal="true" width="800px">
        <div class="filter position-relative mb-4 fs-6 fw-bolder text-gray-600" ref="searchRef">
            <div class="filter-item">
                <span>Folder</span>
                <el-select v-model="filter.folder_id" placeholder="Pilih Folder" clearable @change="onFilter()">
                    <el-option v-for="(o, i) in listFolder" :key="i" :value="o.id" :label="o.name"/>
                </el-select>
            </div>
            <div class="filter-item">
                <span>Search</span>
                <el-input v-model="filter.search" placeholder="Search" clearable />
            </div>
            <div class="d-flex">
                <button class="btn btn-icon btn-light-primary me-3" @click="onFilter()"><i class="fas fa-search"></i></button>
                <button class="btn btn-icon btn-light-danger" @click="resetFilter()"><i class="fas fa-times"></i></button>
            </div>
        </div>
        <div>
            <table class="table table-rounded table-row-dashed table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                <thead>
                    <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                        <th>#</th>
                        <th>Gambar</th>
                        <th>Desain ID</th>
                        <th>Folder</th>
                        <th>Ukuran</th>
                        <th class="text-end w-75px">Stok</th>
                        <th class="text-end w-75px">Action</th>
                    </tr>
                </thead>
                <tbody class="fw-bold text-gray-600">
                    <tr v-for="(item, i) in listDesain" :key="i" class="cursor-pointer" title="Pilih desain">
                        <td scope="row">{{ item.index }}</td>
                        <td>
                            <img class="tbl-image" @click="viewImage(item)" :src="img_url_desain+item.gambar">
                        </td>
                        <td>{{ item.sku }}</td>
                        <td>{{ item.folder }}</td>
                        <td>{{ item.print_size_name }}</td>
                        <td class="text-end w-75px">{{ item.stok_warehouse }}</td>
                        <td class="text-end w-75px">
                            <button class="btn btn-icon btn-light-primary" @click="modalQty = true, tempData.raw = item"><i class="fas fa-plus"></i></button>
                        </td>
                    </tr>
                </tbody>
                <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </table>
        </div>
        <template #footer>
            <div class="text-center">
                <el-pagination background layout="prev, pager, next" :page-count="pagination.total_page" :pager-count="4" @next-click="handlePageChange" @prev-click="handlePageChange" @current-change="handlePageChange" v-model:current-page="pagination.page" />
            </div>
        </template>
    </el-dialog>

    <el-dialog v-model="modalQty" :title="'Tambah Qty'" width="250px" align-center>
        <div> 
            <input type="number" class="form-control" name="qty" v-model="tempData.qty" />
            <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                     <ErrorMessage name="qty" />
                </div>
            </div>

            <div class="d-grid gap-2">
                <button
                  type="button"
                  class="btn btn-primary mt-7"
                  @click="addRaw(tempData.raw)"
                >
                  <span class="indicator-label"> Tambah </span>
              </button>
            </div>
        </div>
    </el-dialog>

    <el-dialog v-model="viewImageVisible" :title="viewImageTitle" width="50%" lock-scroll>
        <inner-image-zoom :src="viewImageSrc" />
    </el-dialog>
</div>
</template>

<style scoped lang="scss">
    $color_primary: #EE7305;
    $add_product_size: 180px;

    .product-distribusi {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(auto, $add_product_size));
        text-align: left;
        position: relative;
        height: 100%;
        overflow-y: scroll;
        padding: .5rem;
        gap: 1rem;
        grid-auto-rows: minmax(min-content, max-content);

        .list-product-distribusi {
            display: flex;
            flex-direction: column;

            img, span {
                margin-bottom: .5rem;
            }

            img {
                border: solid 1px #eee;
                width: 100%;
                height: $add_product_size;
            }

            span {
                text-transform: uppercase;
                font-weight: 500;
            }
        }

        .list-product-distribusi.add-product-distribusi {
            height: $add_product_size;
            align-items: center;
            border: dashed 3px $color_primary;
            cursor: pointer;

            i {
                color: $color_primary !important;
                font-size: 200%;
                line-height: $add_product_size;
                width: 100%;
                text-align: center;
            }
        }

        .list-product-distribusi.add-product-distribusi:hover {
            border-style: solid;
        }
    }
</style>

<script>
import { defineComponent, onBeforeMount, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR, validFileSize, validFileType } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { ElMessage, ElLoading, ElPagination, FormInstance, FormRules } from 'element-plus'

//import { InfoFilled } from '@element-plus/icons-vue'


export default defineComponent({
    components: { 
        //Datatable,
        // Field, 
        // Form,    
        // ErrorMessage, 
    },
    setup() {
        const store = useStore();

        const img_url = ref(process.env.VUE_APP_IMAGE_PRODUCTS)
        const img_url_product = ref(process.env.VUE_APP_IMAGE_PRODUCTS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)

        const loadingTable = ref(false);
        
        const loadingSubmit = ref(false);
        const disabledButton = ref(true)
        const submitButton = ref(null)
        const requiredField = ref(false)

        const formRef = ref(null)

        const modalVisible = ref({
            item: false,
            desain: false,
        })
        const modalTitle = ref('')
        const search = ref(null)

        const queryPengirim = ref('');
        const queryPenerima = ref('');

        const modalItem = ref(false)
        const modalDesain = ref(false)
        const modalImport = ref(false)
        const modalQty = ref(false)

        const tempData = reactive({
            store_id: null,
            store_name: null,
            note: null,
            pengirim_id: '',
            penerima_id: '',
            user_id: '',
            type: null,
            files: '',
            qty: null,
            raw: null,    
        })

        const prevItem = reactive([])
        const prevDesain = reactive([])

        const submitData = reactive([])

        const listAllPengirim = ref([])
        const listAllPenerima = ref([])

        const listCategory = computed(() => store.getters.getListCategory)
        const listFolder = computed(() => store.getters.getListRawFolder)

        const filterActive = ref(false)
        const filter = reactive({
            search: null,
            category_id: null,
            folder_id: null,
        })

        const onFilter = async (type) => {
            filterActive.value = false

            if(tempData.type == 'item') {
                await getRawItem()
            }

            if(tempData.type == 'desain') {
                await getRawDesain()
            }

            pagination.value.page = 1

            Object.values(filter).every(o => o == null || o.length == 0) ?
                filterActive.value = false :
                filterActive.value = true
        }

        const resetFilter = async (type) => {
            Object.keys(filter).map(k => filter[k] = null) 

            filterActive.value = false

            if(tempData.type == 'item') {
                await getRawItem()
            }

            if(tempData.type == 'desain') {
                await getRawDesain()
            }
        }

        const viewImageSrc = ref(null)
        const viewImageTitle = ref('')
        const viewImageVisible = ref(false)

        const viewImage = (data) => {
            viewImageTitle.value = tempData.type == "item" ? 'SKU - '+data.sku : 'DESAIN ID - '+data.sku
            viewImageVisible.value = true
            viewImageSrc.value = img_url.value+data.gambar;
        }

        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

        const handlePageChange = async (page) => {
            if(tempData.type == 'item') {
                await getRawItem()
            } else {
                await getRawDesain()
            }
        }

        const listProduct = reactive([])
        const listDesain = reactive([])

        const getRaw = () => {
            if(tempData.type == 'item') {
                getRawItem()
            } else {
                getRawDesain()
            }
        }

        const getRawItem = async () => {
            try {
                modalItem.value = true
                loadingTable.value = true
                listProduct.splice(0)

                let page = pagination.value.page ? '?page=' + pagination.value.page : '?page=1';
                let category = filter.category_id ? '&category=' + filter.category_id : '';
                let tipe_produk = filter.tipe_produk ? '&tipe_produk=' + filter.tipe_produk : '';
                let search = filter.search ? '&search=' + filter.search : '';

                await ApiService.get("distribusi/list_raw/item" + page + tipe_produk + category + search)
                    .then(({ data }) => {
                        pagination.value = data.data.pagination
                        Object.assign(listProduct, data.data.data)
                    })
                    .catch(({ response }) => {
                        throw new Error('Error getting list raw item');
                    });

            } catch (e) {
                Swal.fire({
                    title: e,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loadingTable.value = false
        }

        const getRawDesain = async () => {
            try {
                modalDesain.value = true
                loadingTable.value = true
                listDesain.splice(0)

                let page = pagination.value.page ? '?page=' + pagination.value.page : '?page=1';
                let folder = filter.folder_id ? '&folder=' + filter.folder_id : '';
                let search = filter.search ? '&search=' + filter.search : '';

                await ApiService.get("distribusi/list_raw/desain" + page + folder + search)
                    .then(({ data }) => {
                        pagination.value = data.data.pagination
                        Object.assign(listDesain, data.data.data)
                    })
                    .catch(({ response }) => {
                        throw new Error('Error getting list raw desain');
                    });

            } catch (e) {
                Swal.fire({
                    title: e,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loadingTable.value = false
        }

        const getPenanggungJawab = async (store_id) => {
          // Compare id on table store to table store_intra
          await ApiService.post("distribusi/list_penanggung_jawab", { store_id: store_id })
            .then(({ data }) => {
              store_id == 1
                ? listAllPengirim.value = data
                : listAllPenerima.value = data
            })
            .catch(({ response }) => {
              console.log(response)
            });
        }

        const filterPengirim = (queryString, cb) => {
          if(queryString) {
            const result = listAllPengirim.value.filter((item) => item.nama_user.toLowerCase().includes(queryString.toLowerCase()))
            cb(JSON.parse(JSON.stringify(result)))
          } else {
            const result = listAllPengirim.value;
            cb(JSON.parse(JSON.stringify(result)))
          }
        }

        const filterPenerima = (queryString, cb) => {
          if(queryString) {
            const result = listAllPenerima.value.filter((item) => item.nama_user.toLowerCase().includes(queryString.toLowerCase()))
            cb(JSON.parse(JSON.stringify(result)))
          } else {
            const result = listAllPenerima.value
            console.log(result)
            cb(JSON.parse(JSON.stringify(result)))
          }
        }

        const handlePengirim = (val) => {
          tempData.pengirim_id = val.id
          queryPengirim.value = val.nama_user
        }

        const handlePenerima = (val) => {
          tempData.penerima_id = val.id
          queryPenerima.value = val.nama_user
        }

        const listStore = reactive([])

        const getStore = () => {
            listStore.splice(0)

            ApiService.setHeader();
            ApiService.get("active_store")
            .then(({ data }) => {
                let specific_store = data.filter(o => o.id != 1)
                Object.assign(listStore, specific_store)
            })
            .catch(({ response }) => {
                console.log('Error getting list store');
            });
        }

        // READ FILE
        const reader = new FileReader();
        const totalSize = ref(0);
        const currentProgress = ref(0);
        
        function handleEvent(event) {
            if (['loadend', 'load'].includes(event.type)) {
                currentProgress.value = 'Completed 100%';
            }
            if (event.type === 'progress') {
                currentProgress.value = `${(event.loaded / totalSize.value).toFixed(2) * 100}%`;
                console.log('Loaded: '+event.loaded)
                console.log('Progress: ', currentProgress.value);
                console.log('Bytes transferred: ', event.loaded, 'bytes');
            }
            if (event.type === 'loadstart') {
                totalSize.value = event.total;
            }
        }

        const switchStore= async () => {
            await prevItem.splice(0);
            await prevDesain.splice(0);

            if(document.getElementById('files')) {
                document.getElementById('files').value = null
            }

            if(submitButton.value) {
                submitButton.value.disabled = true;
            }
            
            await getPenanggungJawab(tempData.store_id) // Store
        }

        const switchRaw = async () => {
            await prevItem.splice(0);
            await prevDesain.splice(0);

            if(document.getElementById('files')) {
                document.getElementById('files').value = null
            }

            if(submitButton.value) {
                submitButton.value.disabled = true;
            }
        }

        const onFileChange = async (e) => {
            const loading = ElLoading.service({
                lock: true,
                text: 'Tunggu',
                background: 'rgba(255,255,255,0.7)'
            })
            
            try {
                if(!tempData.store_id) {
                    Swal.fire({
                        title: "Silakan pilih toko dahulu",
                        icon: "error",
                        showConfirmButton: false,
                        timer: 3000
                    });

                    document.getElementById('files').value = null
                    loading.close()
                    return
                }

                var setData = [];
                var type = tempData.type

                var file = e.target.files[0] ? e.target.files[0] : null;
                tempData.files = e.target.files[0];

                if(type == 'item') {
                    prevItem.splice(0)
                    
                } else if(type == 'desain') {
                    prevDesain.splice(0)
                } else {
                    throw new Error('Type invalid')
                }
                
                const rawText = await file.text()
                let splitText = rawText.split(/\r?\n|\r|\n/g).map(o => o.split('-'))

                for(let i=0; i<splitText.length; i++) {
                    if(splitText[i][0] && splitText[i][1]) {
                        setData.push({ sku: splitText[i][0], qty: splitText[i][1]})
                    }
                }

                // Save to submit
                await submitData.splice(0)
                Object.assign(submitData, setData)

                // Preview data
                await ApiService.post("distribusi/get_raw", { data: setData, type: type, store_id: tempData.store_id })
                .then(({ data }) => {
                    type == 'item'
                        ? Object.assign(prevItem, data.data)
                        : Object.assign(prevDesain, data.data)
                })
                .catch(({ response }) => {
                    throw new Error(response.data.messages.error)
                });

                let checkStatus = type == 'item' 
                                        ? prevItem.every(o => o.status == 1)
                                        : prevDesain.every(o => o.status == 1);

                if(checkStatus) {
                    if(submitButton.value) {
                        submitButton.value.disabled = false;
                    }
                } else {
                    if(submitButton.value) {
                        submitButton.value.disabled = true;
                    }
                }
            }
            catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 3000
                });
            }

            modalImport.value = false
            loading.close()
        }

        const addRaw = async (item) => {
            const loading = ElLoading.service({
                lock: true,
                text: 'Tunggu',
                background: 'rgba(255,255,255,0.7)'
            })

            try {
                if(!tempData.store_id) {
                    Swal.fire({
                        title: "Silakan pilih toko dahulu",
                        icon: "error",
                        showConfirmButton: false,
                        timer: 3000
                    });

                    loading.close()
                    return
                }

                if(tempData.qty <= 0) {
                    Swal.fire({
                        title: "Qty harus lebih dari nol",
                        icon: "error",
                        showConfirmButton: false,
                        timer: 3000
                    });

                    loading.close()
                    return
                }

                let setData = [];

                setData.push({ sku: item.sku, qty: tempData.qty })

                // Preview data
                await ApiService.post("distribusi/get_raw", { data: setData, type: tempData.type, store_id: tempData.store_id })
                .then(({ data }) => {
                    data.data.forEach((o) => {
                        if(tempData.type == 'item') {
                            if(prevItem.find(k => k.sku == o.sku)) {
                                prevItem.map(m => m.sku == o.sku ? m.qty += parseInt(tempData.qty) : null)
                            } else {
                                prevItem.push(o)
                            }

                        } else {
                             if(prevDesain.find(k => k.sku == o.sku)) {
                                prevDesain.map(m => m.sku == o.sku ? m.qty += parseInt(tempData.qty) : null)
                            } else {
                                prevDesain.push(o)
                            }
                        }
                    })
                })
                .catch(({ response }) => {
                    throw new Error(response.data.messages.error)
                });

                let checkStatus = tempData.type == 'item' 
                    ? prevItem.every(o => o.status == 1)
                    : prevDesain.every(o => o.status == 1);

                if(checkStatus) {
                    if(submitButton.value) {
                        submitButton.value.disabled = false;
                    }
                } else {
                    if(submitButton.value) {
                        submitButton.value.disabled = true;
                    }
                }

                tempData.qty = null;
                tempData.raw = null;
                // modalItem.value = false
                // modalDesain.value = false
                modalQty.value = false

            } catch(e) {
                console.log(e)
            }

            loading.close()
        }

        const editRaw = async (item) => {
            tempData.qty = null;
            modalQty.value = true;
            tempData.raw = item
        }

        const removeRaw = async (index) => {
            if(tempData.type == 'item') {
                prevItem.splice(index, 1)
            } else {
                prevDesain.splice(index, 1)
            }
        }

        const onSubmit = async (formEl) => {
            try {
                if (!formEl) return

                let setData = [];

                if(tempData.type == 'item') {
                    let getItem = JSON.parse(JSON.stringify(prevItem)).map(item => ({ sku: item.sku, qty: item.qty}))
                    Object.assign(setData, getItem);
                }

                if(tempData.type == 'desain') {
                    let getDesain = JSON.parse(JSON.stringify(prevDesain)).map(item => ({ sku: item.sku, qty: item.qty}))
                    Object.assign(setData, getDesain);
                }

                if(setData.length == 0) {
                    throw new Error("Silakan pilih produk")
                }

                await formEl.validate( async(valid, fields) => {
                  loadingSubmit.value = true;

                    if (valid) {
                        await ApiService.post("distribusi/create", { data: setData, note: tempData.note, type: tempData.type, store_id: tempData.store_id, pengirim_id: tempData.pengirim_id, penerima_id: tempData.penerima_id })
                        .then(({ data }) => {
                            Swal.fire({
                                title: data.messages,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            //router.push({path: '/distribusi/download/'})
                        })
                        .catch(({ response }) => {  
                            if(response) {
                                throw new Error('Distribustion failed');
                            }
                        });

                    } else {
                        throw new Error('Please check form before submit.')
                    }


                    // Reset All Data
                    prevItem.splice(0)
                    prevDesain.splice(0)
                    submitData.splice(0)

                    if(document.getElementById('files')) {
                        document.getElementById('files').value = null
                    }

                    disabledButton.value = true
                    loadingSubmit.value = false

                    tempData.store_id = ''  
                    
                    queryPengirim.value = null
                    queryPenerima.value = null
                    formEl.resetFields()

                    loadingSubmit.value = false
                    disabledButton.value = true
                })

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 3000
                });
            }

            loadingSubmit.value = false
        }

        onBeforeMount(() => {
            setCurrentPageTitle("Distribusi");
            setCurrentPageBreadcrumbs("Distribusi", ['distribusi']);

            store.dispatch(Actions.LIST_CATEGORY);
            store.dispatch(Actions.LIST_RAW_FOLDER);

            getStore()
            getPenanggungJawab(1) // Office / Warehouse
        })

        onMounted(() => {
            if(submitButton.value) {
                submitButton.value.disabled = true;
            }
        })

        watchEffect(() => {
            tempData.type = 'item'
        })

        watch(() => tempData.store_id, (val) => {
            if(val) {
                tempData.note = 'Distribusi ke '+listStore.find(o => o.id == tempData.store_id).name;
                tempData.store_name = listStore.find(o => o.id == tempData.store_id).name;
            }
        })

        return {
            formRef,
            img_url_desain, img_url_product, modalVisible, modalTitle, disabledButton, loadingSubmit, loadingTable, submitButton, requiredField,
            listCategory, listFolder,
            tempData, submitData, prevItem, prevDesain, listStore,
            modalItem, modalDesain, modalImport, modalQty,
            filter, onFilter, resetFilter, pagination, handlePageChange,
            img_url, viewImage, viewImageSrc, viewImageVisible, viewImageTitle,
            listProduct, listDesain, getRaw,
            listAllPengirim, listAllPenerima, filterPengirim, filterPenerima, handlePengirim, handlePenerima, queryPengirim, queryPenerima,
            switchStore, switchRaw, onFileChange, onSubmit,
            addRaw, removeRaw, editRaw,
        }
    }
})
</script>